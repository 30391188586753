:root {
  --background-color: #F0F0F0;
  --testo-bianco: #F8F8F8;
  --testo-nero: #393939;
}
@font-face {
  font-family: 'Brush Script MT';
  src: url('BRUSHSCI.TTF');
}
html, body, .copertina, .sfondo, .sfondo>img, .biografia {
  width: 100%;
  height: 100vh;
  background-color: var(--background-color);
  font-size: 1.05rem;
}
a:hover {
  text-decoration: none;
}
/* COPERTINA */
.sfondo>img {
  object-fit: cover;
}
.testo {
  position: absolute;
  bottom: 50px;
  left: 50px;
  color: var(--testo-bianco);
  text-shadow: 0 0 8px #333333;
}
.testo h1 {
  font-size: 7rem;
  margin-bottom: 30px;
}
ul.home-menu {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 10px;
}
ul.home-menu li {
  display: inline-block;
}
ul.home-menu li:not(:first-of-type)::before {
  content: "•";
  padding-left: 10px;
  padding-right: 15px;
}
ul.home-menu li a {
  color: inherit;
}
ul.home-menu li a:hover {
  color: #C8C8C8;
}
.info-quadro {
  position: absolute;
  top: 35px;
  right: 25px;
  color: var(--testo-bianco);
  text-shadow: 0 0 8px #333333;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: all 0.6s ease;
}
.info-quadro>.img {
  font-size: 3rem;
  text-align: right;
}
.info-quadro>.descrizione {
  display: none;
}
.info-quadro:hover, .info-quadro.hover {
  background-color: rgba(10,10,10,0.8);
}
.info-quadro:hover>.descrizione, .info-quadro.hover>.descrizione {
  display: block;
  width: 400px;
}
.info-quadro .titolo-quadro {
  margin-top: -50px;
  max-width: 300px;
}
.info-quadro .link {
  text-align: center;
}
@media(max-width: 860px) {
  .testo h1 {
    font-size: 5rem;
  }
  ul.home-menu {
    font-size: 1.3rem;
  }
  .info-quadro>.img {
    font-size: 2.5rem;
  }
}
@media(max-width: 620px) {
  .testo h1 {
    font-size: 4rem;
  }
  ul.home-menu {
    font-size: 1.2rem;
  }
  .info-quadro>.img {
    font-size: 2.1rem;
  }
}
@media(max-width: 500px) {
  .info-quadro:hover, .info-quadro.hover {
    left: 25px;
  }
  .info-quadro:hover>.descrizione, .info-quadro.hover>.descrizione {
    width: auto;
  }
  .info-quadro .titolo-quadro {
    margin-top: 0;
  }
  .testo h1 {
    font-size: 3rem;
  }
  ul.home-menu {
    font-size: 1rem;
  }
}
/* BIOGRAFIA */
.tbl {
  display: table;
  height: 100%;
  width: 1580px;
  margin: 0 auto;
}
.tbc {
  display: table-cell;
  vertical-align: middle;
}
.fotodiv {
  width: 600px;
  perspective: 1000px;
}
.foto {
  position: relative;
  width: 100%;
  transition: transform 2s ease -0.5s, opacity 1.5s;
  transform-style: preserve-3d;
  box-shadow: 5px 5px 10px #555555;
  border: 15px solid white;
  transform: rotateY(0deg);
  backface-visibility: hidden;
  opacity: 1;
}
.foto.hidden {
  transform: rotateY(180deg);
  opacity: 0;
}
.biografia .descrizione, .biografia .link {
  font-family: "Brush Script MT", cursive;
  font-size: 2.3rem;
  margin-left: 50px;
  width: 980px;
  text-align: center;
  line-height: 4rem;
  opacity: 1;
  transition: margin 1s, opacity 1s;
  color: var(--testo-nero);
}
.biografia.hidden .descrizione {
  margin-left: -200px;
  opacity: 0;
}
.biografia .link {
  margin-top: 50px;
  font-size: 1.5rem;
  transition: opacity 1s ease 1s;
}
.biografia.hidden .link {
  opacity: 0;
}
@media(max-width: 1700px) {
  .tbl {
    width: 1450px;
  }
  .biografia .descrizione, .biografia .link {
    width: 850px;
  }
}
@media(max-width: 1500px) {
  .tbl {
    width: 1150px;
  }
  .fotodiv {
    width: 500px;
  }
  .foto {
    border-width: 11px;
  }
  .biografia .descrizione, .biografia .link {
    font-size: 1.8rem;
    width: 650px;
    line-height: 3.2rem;
  }
}
@media(max-width: 1200px) {
  .tbl {
    width: 850px;
  }
  .fotodiv {
    width: 350px;
  }
  .foto {
    border-width: 8px;
  }
  .biografia .descrizione, .biografia .link {
    width: 500px;
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
}
@media(max-width: 950px) {
  .tbl {
    width: 600px;
  }
  .fotodiv {
    width: 200px;
  }
  .foto {
    border-width: 4px;
  }
  .biografia .descrizione, .biografia .link {
    width: 400px;
  }
}
@media(max-width: 700px) {
  .biografia {
    height: auto;
  }
  .tbl, .tbc {
    display: block;
    width: auto;
  }
  .fotodiv {
    width: 100%;
    text-align: center;
    padding-top: 50px;
    margin-bottom: 50px;
  }
  .foto {
    width: 200px;
    max-width: 90%;
  }
  .biografia .descrizione, .biografia .link {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
  }
}
/* OPERE */
.opere-sfondo {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  z-index: -1;
}
.opere-sfondo:after{
  content:"";
  position:fixed;
  top:0;
  height:100vh;
  left:0;
  right:0;
  z-index:-1;
  background-image: url('/img/divisore.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.divisore {
  height: 60vh;
}
.opere {
  min-height: 100%;
  padding-bottom: 50px;
}
.opere .descrizione {
  margin-top: 150px;
  font-family: "Brush Script MT", cursive;
  font-size: 2.3rem;
  line-height: 4rem;
  text-align: center;
  color: var(--testo-bianco);
  text-shadow: 0 0 5px #333333;
  padding-top: 100px;
}
.quadri {
  margin-top: 100px;
  text-align: center;
}
.quadri img {
  height: 280px;
  border: 10px solid white;
  box-shadow: 5px 5px 5px #333333;
}
.quadri img:not(:first-of-type) {
  margin-left: -15px;
  margin-right: 0px;
}
.opere .link {
  text-align: center;
  font-family: "Brush Script MT", cursive;
  font-size: 3rem;
  margin-top: 100px;
  text-shadow: 0 0 5px white;
}
@media(max-width: 1500px) {
  .opere .descrizione, .opere .link {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
}
@media(max-width: 1200px) {
  .quadri img {
    height: 200px;
    border-width: 8px;
  }
}
@media(max-width: 1000px) {
  .opere .descrizione, .opere .link {
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
}
@media(max-width: 991px) {
  .quadri img {
    height: 175px;
    border-width: 5px;
  }
}
@media(max-width: 767px) {
  .quadri img:not(.mobile) {
    display: none;
  }
  .quadri img.mobile {
    width: 90%;
    height: auto;
    margin-left: 0px;
  }
}
/* FOOTER */
.footer {
  background-color: #333333;
  color: #888888;
  padding-bottom: 5px;
  padding-top: 15px;
  text-align: center;
  font-size: 0.9rem;
}
.footer ul {
  list-style: none;
  padding-left: 0px;
}
@media(min-width: 576px) {
  .footer li {
    display: inline-block;
  }
  .footer li:not(:first-of-type)::before {
    content: "•";
    padding-right: 10px;
    padding-left: 5px;
  }
}
.footer li a {
  color: inherit;
}
.footer li a:hover {
  color: #AAAAAA;
}

/* ------------------------ //
// ----- ALTRE PAGINE ----- //
// ------------------------ */

/* MENU */
body.page {
  padding-top: 50px;
}
.menu {
  background-color: white;
  height: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #BBBBBB;
}
.menu .titolo {
  font-size: 1.4rem;
  font-weight: bold;
  color: #777777;
  display: inline-block;
  line-height: 50px;
}
.menudiv {
  display: inline-block;
  position: absolute;
  top: 0;
}
ul.menu {
  display: inline-block;
  position: relative;
  height: 50px;
  top: 0;
  margin: 0 0;
}
ul.menu li {
  display: inline-block;
  height: 50px;
  line-height: 50px;
}
ul.menu li a {
  color: #777777;
}
ul.menu li:not(:first-of-type)::before {
  content: '•';
  color: #777777;
  margin-left: 5px;
  margin-right: 10px;
}
ul.menu li:hover a {
  color: var(--testo-nero);
}
ul.menu li.active a {
  font-weight: 500;
  color: var(--testo-nero);
}
.mobile-menu {
  display: none;
}
@media(min-width: 610px) {
  @media(max-width: 767px) {
    .menu>.container {
        max-width: 600px;
    }
  }
}
@media(max-width: 609px) {
  .menudiv {
    display: block;
    top: inherit;
    left: 0;
    right: 0;
    margin-left: -100%;
    margin-right: 100%;
    transition: ease 0.3s margin-right, ease 0.1s margin-left;
  }
  .menudiv.visibile {
    margin-left: 0;
    margin-right: 0;
  }
  ul.menu {
    display: block;
    height: auto;
    width: 100%;
    padding-left: 0;
    background-color: #FAFAFA;
  }
  ul.menu li {
    height: 35px;
    line-height: 35px;
    display: block;
    padding-left: 20px;
  }
  ul.menu li:not(:first-of-type) {
    border-top: 1px solid #F0F0F0;
  }
  ul.menu li:not(:first-of-type)::before {
    content: '';
    margin-left: 0;
    margin-right: 0;
  }
  .mobile-menu {
    display: block;
    position: absolute;
    width: 40px;
    height: 32px;
    top: 9px;
    right: 9px;
  }
  .mobile-menu>div {
    margin: 5px;
    height: 4px;
    background-color: #777777;
    border-radius: 2px;
  }
}
/* STILE */
h1.title {
  font-family: 'Brush Script MT', cursive;
  font-size: 4rem;
}
h1.title small {
  font-size: 2.3rem;
  color: #555555;
}
h4 {
  font-family: 'Brush Script MT', cursive;
  text-align: center;
}
.corpo {
  text-align: justify;
  padding-top: 20px;
}
.testata {
  width: 100%;
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.corpo ul {
  list-style-type: none;
}
.corpo li::before {
  content: '';
  display: inline-block;
  background-image: url('data:image/svg+xml,<svg viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>');
  width: 0.7rem;
  height: 0.7rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -20px;
  margin-right: 10px;
}
/* OPERE */
.tele {
  text-align: left;
}
.tele.tele-lg {
  display: none;
}
@media(min-width: 1200px) {
  .tele.tele-lg {
    display: block;
  }
  .tele.tele-mobile {
    display: none;
    text-align: justify;
  }
}
.tele .opera {
  background-size: 100%;
  background-origin: content-box;
  background-position: center;
  display: inline-block;
  margin-top: -2px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.tele .opera:hover {
  background-size: 120%;
}
.tele-mobile img {
  width: 100%;
  padding-bottom: 10px;
}
.bigopera {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(100,100,100,0.5);
  opacity: 1;
  transition: opacity 0.8s ease;
}
.bigopera.hidden {
  opacity: 0;
  right: 100vw;
  bottom: 100vh;
}
.bigopera .background {
  width: 100%;
  height: 100%;
}
.bigopera .contenitore {
  width: 1200px;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 40px;
  bottom: 40px;
  background-color: var(--background-color);
  margin: 0 auto;
  border: 1px solid #777777;
  border-radius: 20px;
  transition: all 0.8s ease;
}
.bigopera.hidden .contenitore {
  top: -100vh;
  bottom: 100vh;
}
.bigopera .contenitore .quadro {
  background-color: #777777;
  width: 800px;
  height: 100%;
  border: 1px solid #777777;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}
.bigopera .contenitore .descrizione {
  display: inline-block;
  height: 100%;
  position: absolute;
  top: 0;
  padding: 10px;
  width: 400px;
}
.bigopera .contenitore .descrizione .dismiss {
  position: absolute;
  right: 20px;
  top: 0;
  font-size: 2rem;
  color: #777777;
  font-weight: bold;
  cursor: pointer;
}
.bigopera .contenitore .descrizione h3 {
  margin-top: 50px;
}
.bigopera .contenitore .descrizione .caratteristiche {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #777777;
}
.bigopera .opera-link {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
@media(max-width: 1199px) {
  .bigopera .contenitore {
    width: 90%;
  }
  .bigopera .contenitore .quadro {
    display: none;
  }
  .bigopera .contenitore .descrizione {
    display: block;
    width: 100%;
  }
}
.copy {
  text-align: center;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #777777;
}
